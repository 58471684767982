
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ITooth, IToothDamage, IToothSurface } from '../types';

@Component
export default class ToothTheraDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly tooth: ITooth | undefined;

  @Prop({ default: () => 0 })
  readonly startTab: number | undefined;

  item: ITooth | null = null;
  tab = 0;
  damagesListVisible = false;
  damageAdded: any = {}

  @Watch('value')
  onShow() {
    if (this.value) {
      this.tab = this.startTab || 0;
      this.item = $app.clone(this.tooth);
      this.damageAdded = {};
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get excludeDamages() {
    const exclude = {};
    if (this.item && this.item.damages) {
      this.item.damages.forEach(d => Vue.set(exclude, d.code, true));
    }
    return exclude;
  }

  store() {
    this.item!.damages.forEach(d => { d.appended = this.damageAdded[d.code] === true });
    this.$emit('store', this.item)
  }

  addDamage() {
    this.damagesListVisible = true;
  }

  async reloadTooth() {
    this.item = await $app.get('/api/patients/' + this.item!.patientId + '/teeth/' + this.item!.number);
    this.$emit('update', this.item)
  }

  async select(toohDamage: IToothDamage) {
    toohDamage.damage!.processing = true;
    try {
      await $app.post('/api/patients/' + this.item!.patientId + '/teeth/' + toohDamage.number + '/damages', toohDamage);
      this.item!.damages.push(toohDamage);
      await this.reloadTooth();
      this.damageAdded[toohDamage.code] = true;
    } catch (err) {
      $app.pushError(err);
    }
    toohDamage.damage!.processing = false;
  }

  async removeDamage(damage: IToothDamage) {
    damage.processing = true;
    try {
      await $app.delete('/api/patients/' + this.item!.patientId + '/teeth/' + damage.number + '/damages/' + damage.code);
      this.item!.damages = this.item!.damages.filter(i => i.code !== damage.code);
      await this.reloadTooth();
      this.damageAdded[damage.code] = false;
    } catch (err) {
      $app.pushError(err);
    }
    damage.processing = false;
  }

  setToothSurface(surface: IToothSurface, cure: string) {
    surface.cure = cure;
  }
}
